
body:has(.dialog[data-open]) {
    overflow: hidden;
}
  
.dialog-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) to control transparency */
    z-index: 1100;
}

.dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents container from being scrolled */
    z-index: 1000;
    animation-name: fade;
    animation-duration: 0.2s;
}
  
.dialog-wrapper {
  
    min-width: 400px;
    max-width: 700px;
    max-height: 90%;
    
    position: relative;
    margin: 0 auto;
    border-radius: 20px;
    overflow: scroll;
}
  
@keyframes fade {
    from {opacity: 0;}
    to {opacity: 1;}
}
  
.dialog {
    /* position: relative;
    width: 700px;
    max-width: 100%;
    max-height: 90%;
    
  
    box-sizing: border-box;
    background: white;
    padding: 16px;
    border-radius: inherit;
    z-index: 12;
    animation-name: fade;
    animation-duration: 0.2s;
    overflow: auto; */
  
    width: 100%;
    max-height: 100%;
    position: relative;
    background: white;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    overflow: auto; /* Enable scrolling for dialog */
    z-index: 1200;
}
  
dialog h1, dialog h2, dialog h3, dialog h4, dialog h5, dialog h6, dialog p {
    color: black;
}
  
  
.dialogTopBar {
    position: sticky; /* Sticky position for the top bar */
    top: 0;
    background: transparent;
    z-index: 2000;
    padding: 8px 16px;
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialogTopBarLeftItem {
    height: 44px;
    width: 100px;
    text-align: center;
    margin-left: 40px;
}
  
.dialogTopBarLeftItem button {
    height: 34px;
    border-radius: 17px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
  
.dialogTopBarLeftItem button:hover {
    background: lightgray;
}
  
.dialogTopBarCenterItem {
    --webkit-flex: 1;
    flex: 1;
    height: 44px;
    text-align: center;
}
  
  
.closeButton {
    padding: 0;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background: lightgray;
    border: none;
    font-size: 22px;
    line-height: 34px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



@media screen and (max-width: 700px) {
    .dialog-wrapper {
        width: calc(100% - 32px);
        max-width: calc(100% - 32px);
    }
    
}