
button.firebaseui-idp-button {
    border-radius: 30px;
    height: 60px;
}

.auth-dialog-content {
    padding-top: 0px;
    padding-bottom: 3em;
}

.auth-dialog-content p {
    font-size: 20px;
    margin: 0;
    margin-bottom: 0.5em;
    color: black;
}

.loginWithEmailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

p.error {
    color: red;
    font-size: 16px;
    margin: 0;
    margin-bottom: 0.5em;
}

p.success {
    color: green;
    font-size: 16px;
    margin: 0;
    margin-bottom: 0.5em;
}