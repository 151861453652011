
h3.paypalCheckout {
  color: black;
  margin: 0 auto;
  max-width: 300px;
}
p.paypalCheckout {
  color: black;
  font-size: 20px;
  margin: 0;
  margin-bottom: 0.5em;
}

h4.paypalCheckout {
  color: black;
  font-size: 40px;
  margin: 0;
  margin-bottom: 0.5em;
}

p.paypalCheckout.strikethrough {
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 20px;
  text-decoration: line-through;
}