.chess-separator {
    
    width: 80px;
    height: 8px;
    
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 8em;
    margin-bottom: 8em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* white border shadow */
    /* box-shadow: 0 0 1px rgba(255, 255, 255, 0.2); */

  }
  
  .rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  .tileRow {
    display: flex;
    width: 100%;
    margin: 0px;
  }
  
  .tile {
    width: 8px;
    height: 8px;
    margin: 0px;
  }