  
  .dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }
  
  .dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }
  
  .dropdown .selected-value input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
    cursor: default;
    outline: none;
    
    /*"transition: all 200ms ease" with all browser prefixes */
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;

    width: 100%;
    max-width: 400px;
  }
  
  .dropdown .options {
    display: none;
    background-color: #fff;
    border-radius: 27px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;

    margin: 8px auto;
    width: 100%;
    max-width: 400px;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    
  }

  .dropdown .options.selected {
    border: 2px solid #2ecc71;
  }
  
  .dropdown .options.open {
    display: block;
  }
  
  .dropdown .option {
    height: 54px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
    
    color: rgba(51, 51, 51, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 16px;
    align-items: center;
    cursor: pointer;
  }

  .dropdown .options.selected .option {
    cursor: default;
  }

  .dropdown .option:hover {
    background-color: #f1f1f1;
  }

  .option * {
    /* display: inline-block; */
    color: black;
  }

  .option p {
    margin-bottom: 0;
  }

  .error {
    margin-top: 1em;
    color: #e74c3c;
  }

  .icon {
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    background-color: gray;
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }
  